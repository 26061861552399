






































































import Vue from 'vue';
import msg from '@/services/userMsg';
import { get } from '@/services/http';
import { Coords } from '@/services/Coords';
import {
  withTooltip, Marker, originMarker, destinationMarker, stopMarker, filterMarkers,
} from '@/components/map/Marker';
import { RecorridoInfo, CalificacionInfo, CostosInfo } from '@/models/requests-and-responses/sgv';
import {
  propOfType, capitalizeText, isFirefox, getFullName, formatStringNumber,
  copyStringToClipboard, getDetailsPaxList, toTwoDecimals,
} from '@/services/utils';
import taaxiiService from '@/services/taaxii.com/taaxiiService';
import Transportista from '@/models/taaxii.com/taaxiiTransportista';
import Pedido, { HeaderData } from '@/models/Pedido';
import IconClose24 from '@/components/icons/IconClose24.vue';
import Map from '@/components/map/Map.vue';
import SolicitudExtras from '@/models/taaxii.com/SolicitudExtras';
import ViajeExtras from '@/models/taaxii.com/ViajeExtras';
import { SolicitudPasaje } from '@/models/ExtrasData';
import {
  Recorrido, Evento, Direccion, PassengerModel,
} from '@/models/requests-and-responses/seguimiento';
import Route from '@/modules/tracking/components/Recorrido.vue';
import { User } from '@/models/User';
import { eventBus } from '@/main';
import Header from './components/Header.vue';
import DetailsHorizontalTimeline from './components/DetailsHorizontalTimeline.vue';
import DetailsPassengersList from './components/DetailsPassengersList.vue';
import DetailsDriver from './components/DetailsDriver.vue';
import DetailsCalification from './components/DetailsCalification.vue';
import DetailsCosts from './components/DetailsCosts.vue';

export default Vue.extend({
  props: {
    pedido: propOfType<Pedido>(),
  },
  components: {
    Header,
    DetailsHorizontalTimeline,
    Route,
    DetailsPassengersList,
    DetailsCalification,
    DetailsCosts,
    IconClose24,
    Map,
    DetailsDriver,
  },
  data() {
    return {
      infoCostos: null as CostosInfo | null,
      infoCalificacion: [] as CalificacionInfo[],
      selectedStops: [] as number[],
      isExpanded: false,
      isRecorridoReal: false,
      recorridoReal: null as Recorrido | null,
      recorridoCotizado: null as Recorrido | null,
      eventos: [] as Evento[],
      paradas: this.pedido.extras.paradas,
      updateAtClose: false,
      dataToUpdate: {} as { idViaje: number, idSolicitud: number },
    };
  },
  created() {
    this.updatePedido();
    eventBus.$on('updateAtClose', (idViaje: number, idSolicitud: number) => {
      this.updateAtClose = true;
      this.dataToUpdate = { idViaje, idSolicitud };
    });
  },
  beforeDestroy() {
    this.updatePedido();
    if (this.updateAtClose) {
      eventBus.$emit('updateConfirmado', this.dataToUpdate.idViaje, this.dataToUpdate.idSolicitud);
    }
  },
  mounted() {
    this.isRecorridoReal = this.showRecorridoReal;
    this.loadCosts();
    this.loadCalificacion();
    this.fetchRecorrido(true);
    this.fetchRecorrido(false);
  },
  methods: {
    updatePedido() {
      if (this.pedido && this.estado !== 'Registrado') eventBus.$emit('updatePedido', this.pedido);
    },
    fetchRecorrido(showReal: boolean) {
      const { idPublic } = this.pedido;
      get('/pedidos/seguimiento/$pedidoPublicId/recorrido', { pedidoPublicId: idPublic, showReal: showReal.toString() })
        .then((result) => {
          if (showReal) {
            this.recorridoReal = result.data;
          } else {
            this.recorridoCotizado = result.data;
          }
        });
    },
    loadCosts() {
      taaxiiService.getCostos(this.pedido.resources)
        .then((result) => { this.infoCostos = result.costos; })
        .catch(() => { this.infoCostos = null; });
    },
    loadCalificacion() {
      if (this.pedido.extras.type === 'v') {
        const { idViaje } = this.pedido.extras;
        get('$sgv/rest/viajes/$idViaje/calificaciones', { idViaje })
          .then(((result) => {
            this.infoCalificacion = result.data;
          }));
      }
    },
    parseAddress(address: string): string {
      return address.split(',', 1)[0];
    },
    getAddress(index: number): string {
      return this.infoRecorrido?.paradas[index]
        ?.dir?.direccion.split(',', 1)[0] || '-';
    },
    scrollTo(component: string) {
      const top = this.$refs[component][0]?.$el.offsetTop - 85;
      const container = this.$refs.sections as Element;
      container.scrollTo({
        behavior: 'smooth',
        top,
      });
    },
    copy(str: string) {
      copyStringToClipboard(str)
        .then(() => this.$toast.success(msg.getSuccess('string_copied')));
    },
    formatStringNumber(number: number): string {
      return formatStringNumber(toTwoDecimals(number));
    },
  },
  computed: {
    user(): User {
      return this.$store.state.user as User;
    },
    extras(): ViajeExtras | SolicitudExtras {
      return this.pedido.extras;
    },
    isIntegrated(): boolean {
      if (this.extras.type === 's') {
        return true;
      }

      return Boolean(this.extras.agencia?.integrado);
    },
    infoRecorrido(): RecorridoInfo | null {
      return {
        paradas: this.pedido.extras.paradas,
        pasajes: this.pedido.extras.pasajes,
      };
    },
    cliente(): string {
      return capitalizeText(this.extras.cliente?.alias || '-');
    },
    servicio(): string {
      if (this.extras.type === 'v') {
        return capitalizeText(`Servicio ${this.extras.vehiculo?.tipoVehiculo.alias || 'No Informado'}`);
      }
      return capitalizeText(`Servicio ${this.pedido.extras.pasajes[0].servicio}`);
    },
    estado(): string {
      return capitalizeText(this.pedido.status);
    },
    passengersList(): PassengerModel[] {
      return getDetailsPaxList(this.pedido.extras.pasajes, this.pedido.extras.paradas);
    },
    driver(): Transportista | null {
      if (this.extras.type === 'v') {
        const { agencia, chofer, vehiculo } = this.extras;
        return {
          agencia,
          chofer,
          vehiculo,
          choferFoto: chofer?.picUrl || undefined,
        };
      }
      return null;
    },
    costs(): CostosInfo | null {
      if (this.isAnulado) {
        return {
          idViaje: 0,
          importeTotal: 0,
          precotizacion: 0,
          detalle: [],
        } as CostosInfo;
      }
      if (this.infoCostos) {
        return {
          ...this.infoCostos,
          coefProrrateo: this.passenger?.coefProrrateo || 100,
        };
      }
      return null;
    },
    passenger(): SolicitudPasaje | null {
      if (this.pedido.type !== 'trip') {
        return null;
      }
      const passenger = this.extras.pasajes.find(({ pasajero }) => (
        getFullName(pasajero).toLowerCase()
          === `${this.user?.apellido}, ${this.user?.nombre}`.toLowerCase()
      )) || this.extras.pasajes[0];

      return passenger;
    },
    id(): number {
      if (this.passenger) {
        return this.passenger.idSolicitud;
      }
      return this.extras.pasajes[0].idSolicitud;
    },
    hrefSeguimiento(): string {
      return `/seguimiento/${this.pedido.idPublic}`;
    },
    /* Firefox y Chrome tienen diferentes anchos de scrollbar,
    asi que le corresponden diferentes paddings */
    scrollbarClass(): string {
      return isFirefox() ? 'pr-5' : 'pr-2';
    },
    mapMarkers() {
      // Lo que vamos a devolver.
      const ret: Array<{ coords: Coords, marker: Marker }> = [];
      const markers = this.isRecorridoReal
        ? this.recorridoReal?.paradas : this.recorridoCotizado?.paradas;
      // Marcadores
      if (markers && markers.length > 0) {
        markers.forEach((parada, i, paradas) => {
          const isSelected = (this.selectedStops.length === 0
            || (i >= this.selectedStops[0] && i <= this.selectedStops[1]));
          let tooltip = `parada ${i}`;
          let marker = stopMarker(isSelected);

          if (i === 0) {
            tooltip = 'origen';
            marker = originMarker(isSelected);
          }

          if (i === (paradas.length - 1)) {
            tooltip = 'destino';
            marker = destinationMarker(isSelected);
          }

          ret.push({
            coords: parada,
            marker: withTooltip(
              tooltip,
              marker,
            ),
          });
        });
      }
      return ret;
    },
    filteredMarkers(): Array<{ coords: Coords, marker: Marker } | undefined> {
      return filterMarkers(this.mapMarkers, false);
    },
    trackerRoute(): Coords[] {
      return this.isRecorridoReal ? this.recorridoReal?.recorrido || [] : [];
    },
    route(): Coords[] {
      return this.isRecorridoReal ? [] : this.recorridoCotizado?.recorrido || [];
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
    navbarHeight(): number {
      return this.$store.getters.navbarHeight;
    },
    mapPadding(): { left: number, top: number, bottom: number, right: number } {
      return {
        left: 600, right: 50, top: 130, bottom: 40,
      };
    },
    recorridoLabel(): string {
      const label = this.isRecorridoReal
        ? msg.getHelpMessage('recorridoTransportista')
        : msg.getHelpMessage('recorridoCotizado');
      if (this.duracion === '0') return `${label} • ${this.distancia} km`;
      return `${label} • ${this.distancia} km en ${this.duracion} min`;
    },
    isFinalizado(): boolean {
      return this.pedido.status === 'finalizado';
    },
    isCalificado(): boolean {
      return this.pedido.status === 'calificado';
    },
    isAnulado(): boolean {
      return this.pedido.status === 'anulado';
    },
    isCanceladoFDT(): boolean {
      return this.isFinalizado && this.pedido.substatus === 'Cancelado en origen';
    },
    isCanceladoFallo(): boolean {
      return this.isFinalizado && this.pedido.substatus === 'Fallo';
    },
    showRecorridoReal(): boolean {
      return ((this.isFinalizado
              && !this.isCanceladoFDT
              && !this.isCanceladoFallo)
              || this.isCalificado)
              && this.isIntegrated;
    },
    calificationTimestamp(): string {
      if (this.infoCalificacion.length === 0) return '';
      return this.infoCalificacion[0].timestamp;
    },
    anuladoTimestamp(): string | Date {
      if (!this.isAnulado) return '';
      return this.pedido.created as Date;
    },
    canceladoTimestamp(): string | Date {
      if (this.pedido.extras.type === 'v') {
        return this.pedido.extras.fin || '';
      }
      return '';
    },
    headerData(): HeaderData {
      return {
        pedidoId: this.id,
        cliente: this.cliente,
        servicio: this.servicio,
        fechaHoraSalida: this.pedido.extras.inicio,
      };
    },
    paradasList(): Direccion[] {
      const paradas = [] as Direccion[];
      this.pedido.extras.paradas.forEach((parada) => {
        paradas.push(parada.dir);
      });
      return paradas;
    },
    haySeguimiento(): boolean {
      return this.pedido.status === 'confirmado';
    },
    distancia(): string {
      if (this.isRecorridoReal) {
        const extras = this.pedido.extras as ViajeExtras;
        return this.formatStringNumber(extras.detalle?.find(
          (e) => e.concepto === 'kilometros',
        )?.cantidad || 0);
      } return this.formatStringNumber(this.pedido.extras.distancia);
    },
    duracion(): string {
      let duracion = 0;
      if (!this.isRecorridoReal && this.pedido.extras.type === 'v') {
        duracion = this.pedido.extras.duracionCotizada || 0;
      } else {
        duracion = this.pedido.extras.duracion || 0;
      }
      return duracion ? duracion.toString() : '0';
    },
  },
});
