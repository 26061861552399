















































































import { eventBus } from '@/main';
import { performAddressSearch, getRealCoords, checkIfAddressIsTerminal } from '@/modules/site/services/addressService';
import IconOrigin from '@/components/icons/IconOriginWithTrail.vue';
import IconDestin from '@/components/icons/IconDestinWithTrail.vue';
import IconStop from '@/components/icons/IconStopWithTrail.vue';
import Vue from 'vue';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import msg from '@/services/userMsg';
import { arrayMapNotNull, getUserLocation, propOfType } from '@/services/utils';
import {
  addressFromUnknown, nameOf, localityOf, Address,
} from '@/models/Address';
import IconPlus24 from '@/components/icons/IconPlus24.vue';
import IconClose from '@/components/icons/IconX20.vue';
import axios from 'axios';
import AddressItem, { newSuggestionAddress, SuggestionAddress } from './AddressItem.vue';
import SectionLayout from '../SectionLayout.vue';
import { AddressOfForm, apply } from '../../indexForm.vue';
import { Metadata } from '../../../CreateWeb.vue';

const MIN_SEARCH_CHARS = 3;

export default Vue.extend({
  name: 'address-section',
  components: {
    SectionLayout,
    AddressItem,
    IconOrigin,
    IconDestin,
    IconStop,
    IconPlus24,
    IconClose,
  },
  props: {
    isExpanded: propOfType<Boolean>(),
    userTriedToConfirm: propOfType<Boolean>(),
    showPlusButton: propOfType<Boolean>(false),
    showXButton: propOfType<Boolean>(false),
    maxStops: propOfType<Boolean>(false),
    metadata: propOfType<Metadata>(),
    addressIndex: propOfType<number>(() => 0),
    initialAddress: propOfType<AddressOfForm>(() => undefined),
  },
  data() {
    return {
      suggestList: [] as any[],
      loading: false,
      text: '',
      timeout: null as null | ReturnType<typeof setTimeout>,
      selectedItem: -1,
      selectedAddress: null as (SuggestionAddress | null),
      abortController: null as null | AbortController,
    };
  },
  mounted() {
    this.updateAddress(this.initialAddress);
  },
  computed: {
    minSearchChars(): number {
      return MIN_SEARCH_CHARS;
    },
    idCliente(): number {
      return this.$store.getters.idCliente as number;
    },
    isDestination(): boolean { // Override this method.
      return false;
    },
    error() {
      if (!this.userTriedToConfirm) return '';
      if (this.selectedAddress) return '';
      return 'Seleccione una dirección.';
    },
    list(): SuggestionAddress[] {
      if (this.suggestList.length) {
        return this.suggestList.map((address) => newSuggestionAddress(
          nameOf(address),
          localityOf(address),
          address,
        ));
      }

      return [];
    },
    headerText(): string {
      if (this.metadata.type === 'PARAMI') {
        if (this.isDestination) {
          return '¿A dónde te llevamos?';
        }
        return '¿Dónde te buscamos?';
      }
      if (this.isDestination) {
        return '¿A dónde lo llevamos?';
      }
      return '¿Dónde lo buscamos?';
    },
    isRemovable(): boolean {
      return (this.selectedAddress !== null && !this.isExpanded);
    },
  },
  watch: {
    text(val) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (val.length > MIN_SEARCH_CHARS) {
          this.fetchSuggestedList(val);
        } else {
          this.fetchSuggestedList('');
        }
      }, 600);
    },
    isExpanded(val) {
      if (val) {
        this.fetchSuggestedList('');
      } else {
        this.text = '';
      }
    },
    initialAddress(newAddress: AddressOfForm) {
      this.updateAddress(newAddress);
    },
  },
  methods: {
    fetchSuggestedList(filter) {
      if (this.loading && this.abortController) {
        this.abortController.abort();
      }
      this.loading = true;
      this.abortController = new AbortController();
      let requestWasCancelled = false;
      performAddressSearch(filter, this.idCliente,
        getUserLocation(), this.abortController.signal)
        .then((response) => {
          this.suggestList = arrayMapNotNull(response, addressFromUnknown);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
          } else {
            requestWasCancelled = true;
          }
        })
        .finally(() => {
          if (!requestWasCancelled) {
            this.abortController = null;
            this.loading = false;
          }
        });
    },
    onHeaderClick() {
      this.$emit('open');
      Vue.nextTick(() => (this.$refs.inputElement as HTMLElement).focus());
    },
    onEndIconClick() {
      if (this.isRemovable) {
        this.selectedAddress = null;
        apply(this, (data) => {
          const adds = [...data.addresses];

          adds[this.addressIndex] = undefined;
          data.addresses = adds;
        });
      }
      this.$emit('toggle');
    },
    getTag(): string {
      if (this.addressIndex === 0) return 'Origen';
      if (this.isDestination) return 'Destino';
      return `Parada ${this.addressIndex}`;
    },
    async onItemClick(item: SuggestionAddress) {
      this.selectedAddress = item;
      this.text = '';
      this.$emit('close');
      (document.activeElement as HTMLElement).blur();
      const { address } = this.selectedAddress;
      this.loading = true;

      if (!address.lat || !address.lon) {
        await getRealCoords(address.source || '')
          .then(({
            id,
            street,
            lat,
            lon,
          }) => {
            address.id = id;
            address.street = street;
            address.lat = lat;
            address.lon = lon;
            this.updateAddress(address);
          })
          .catch((error) => {
            this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
          });
      }

      checkIfAddressIsTerminal(address.street).then((isTerminal) => {
        apply(this, (data) => {
          const adds = [...data.addresses];

          // Funcionamiento que hay que eliminar en algún momento.
          // Sirve para provocar más escenarios en los cuales
          // se diga que una dirección es de tipo terminal.
          const isTerminal2 = isTerminal || address.street.toLowerCase().indexOf('aero') >= 0;

          adds[this.addressIndex] = {
            ...address,
            ascensos: adds[this.addressIndex]?.ascensos,
            descensos: adds[this.addressIndex]?.descensos,
            isTerminal: isTerminal2,
            tag: this.getTag(),
          };
          data.addresses = adds;
        });
      }).catch((error) => {
        this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
      }).finally(() => {
        this.loading = false;
        if (this.isDestination) {
          eventBus.$emit('shrink-bottomsheet');
        }
      });
    },
    updateAddress(newAddress: AddressOfForm | Address) {
      if (newAddress) {
        this.selectedAddress = newSuggestionAddress(
          nameOf(newAddress),
          localityOf(newAddress),
          newAddress,
        );
      } else {
        this.selectedAddress = null;
      }
    },
    onItemSelect(n: number) {
      this.selectedItem += n;
      if (this.selectedItem < 0 || this.selectedItem >= this.list.length) {
        this.selectedItem = 0;
      }
      this.$refs[this.selectedItem][0].$refs.address.focus();
    },
  },
});
