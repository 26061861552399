






























































import Vue from 'vue';
import msg from '@/services/userMsg';
import { Address } from '@/models/Address';
import store from '@/services/store';
import { getSelectedAccount } from '@/services/store/state';
import { GeneralAccount, getIdCliente } from '@/models/accounts/GeneralAccount';
import { Permissions } from '@/models/accounts/shared';
import { arrayMapNotNull } from '@/services/utils';
import { fetchClientes } from '@/services/api/empleadosApi';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import Map from '@/modules/site/components/Map.vue';
import PedidoItem from './PedidoItem.vue';
import ParaMi from './form/forms/ParaMi.vue';
import ParaOtro from './form/forms/ParaOtro.vue';
import ParaInvitado from './form/forms/ParaInvitado.vue';
import ConParadas from './form/forms/ConParadas.vue';
import Mensajeria from './form/forms/Mensajeria.vue';
import Paqueteria from './form/forms/Paqueteria.vue';
import BigButton from './form/BigButton.vue';
import Created from './Created.vue';
import { DataOfSomeForm } from './form/indexForm.vue';

// Añadir aquí en caso de crear un nuevo formulario
const formComponents = {
  ParaMi,
  ConParadas,
  ParaOtro,
  Mensajeria,
  Paqueteria,
  ParaInvitado,
};

type FormStringType = keyof typeof formComponents;

/** Devuelve los formularios que están habilitados para el usuario según
 * la cuenta que está seleccionada.
 *
 * La opción 'Paqueteria' está deshabilitada siempre según fue requerido en TX-1938.
 */
function enabledForms(account: GeneralAccount): FormStringType[] {
  if (account.tag === 'particularAccount') {
    // TODO Habilitar cuentas particulares
    return [];
  }
  return enabledFormsFromPermissions(account.account.permisos);
}

/** Devuelve qué formularios están habilitados según los permisos de una cuenta (corporativa).
 * Ver TX-1939.
 * El mapeo entre permisos y tipos de pedidos también está en labels.json.
 */
function enabledFormsFromPermissions(permissions: Permissions): FormStringType[] {
  return arrayMapNotNull(
    [
      (permissions.travel && permissions.registerGoRequest) ? 'ParaMi' : null,
      permissions.registerBoxRequest ? 'ParaOtro' : null,
      permissions.registerBusRequest ? 'ParaInvitado' : null,
      permissions.registerStopRequest ? 'ConParadas' : null,
      permissions.registerExpressRequest ? 'Mensajeria' : null,
    ],
    (x: FormStringType | null) => x,
  );
}

export type AddressOfMap = Address & {
  tag: string;
};

// Añadir aquí en caso de crear un nuevo formulario
const formMetadata = {
  ParaMi: {
    type: 'PARAMI',
    name: 'Viaje para mí',
    description: 'TODO',
    icon: 'TODO',
  },
  ParaOtro: {
    type: 'PARAOTRO',
    name: 'Viaje para otro usuario',
    description: 'TODO',
    icon: 'TODO',
  },
  ParaInvitado: {
    type: 'PARAINVITADO',
    name: 'Viaje para un invitado',
    description: 'TODO',
    icon: 'TODO',
  },
  ConParadas: {
    type: 'CONPARADAS',
    name: 'Viaje con paradas / múltiples pasajeros',
    description: 'TODO',
    icon: 'TODO',
  },
  Mensajeria: {
    type: 'MENSAJERIA',
    name: 'Envíos',
    description: 'TODO',
    icon: 'TODO',
  },
  Paqueteria: {
    type: 'PAQUETERIA',
    name: 'Envíos (Paquetería)',
    description: 'TODO',
    icon: 'TODO',
  },
} as const;

export type Metadata = typeof formMetadata[keyof typeof formMetadata];

export default Vue.extend({
  components: {
    ...formComponents,
    Map,
    PedidoItem,
    BigButton,
    Created,
  },
  props: {},
  data() {
    const selectedAccount = getSelectedAccount(store.state);
    const forms = selectedAccount === null ? [] : enabledForms(selectedAccount);

    return {
      sectionName: 'nuevo pedido',
      showedForm: null as FormStringType | null,
      isFormSelected: false,
      selectedForm: forms[0] as FormStringType,
      forms,
      addressesOfMap: [] as AddressOfMap[],
      timeEstimation: 0 as number,
      antelacionLAP: 30 as number,
      formMetadata,
      dataFormOfCreated: null as DataOfSomeForm | null,
      distanceKm: 0,
    };
  },
  mounted() {
    document.title = `${this.sectionData.title} - InPunto`;
    // Si la persona no tiene permisos para hacer ningun tipo de pedido,
    // lo redirecciona a 'Mis Pedidos'
    if (this.forms.length === 0) {
      this.$router.push({ name: 'pedidos' }).catch((error) => {});
    }
    this.fetchAntelacionLAP();

    this.$emit('onTimeChangedCallback', this.onTimeChanged);
    this.$emit('onDistanceChangedCallback', this.onDistanceChanged);
  },
  watch: {
    selectedAccount() {
      if (this.selectedAccount !== null) {
        this.forms = enabledForms(this.selectedAccount);
        [this.selectedForm] = this.forms;
      }
    },
    showedForm() {
      if (this.showedForm !== null) {
        this.isFormSelected = true;
      }
      this.$emit('formSelected', this.isFormSelected);
    },
    addressesOfMap() {
      this.$emit('onAddressesChanged', this.addressesOfMap);
    },
  },
  computed: {
    sectionData(): { title: string, desc: string} {
      const title = msg.getTitle(this.sectionName);
      const desc = msg.getDescription(this.sectionName);
      return {
        title,
        desc,
      };
    },
    selectedAccount(): GeneralAccount | null { return store.getters.selectedAccount; },
    idCliente(): number | null {
      return this.selectedAccount !== null
        ? getIdCliente(this.selectedAccount)
        : null;
    },
    isMobile(): boolean {
      return store.getters.isMobile;
    },
    availableForms(): FormStringType[] {
      if (this.isMobile) return this.forms.filter((f) => f === 'ParaMi' || f === 'ParaOtro' || f === 'ParaInvitado');
      return this.forms;
    },
  },
  methods: {
    onItemSelection(value: FormStringType) {
      this.selectedForm = value;
    },
    onButtonConfirm() {
      this.showedForm = this.selectedForm;

      const cliente = localStorage.getItem('cliente') || 'invitado';

      this.$addUserProperties({
        username: localStorage.getItem('username') || 'invitado',
        cliente,
        screen_size: `${window.screen.width}x${window.screen.height}`,
        viewport_size: `${window.innerWidth}x${window.innerHeight}`,
      });

      this.$logEvent('nuevo_pedido', { tipo_pedido: this.selectedForm });
    },
    onExitFromForm(data: DataOfSomeForm) {
      this.dataFormOfCreated = data;
      this.showedForm = null;
      if (this.dataFormOfCreated === null) {
        this.onExitFromPostForm();
      }
    },
    onExitFromPostForm() {
      this.addressesOfMap = [];
      this.dataFormOfCreated = null;
      this.isFormSelected = false;
      this.$emit('formSelected', this.isFormSelected);
    },
    onTimeChanged(t: number) {
      if (t !== 0) this.timeEstimation = t;
    },
    onDistanceChanged(d: number) {
      if (d !== 0) this.distanceKm = d;
    },
    toggleMap() {
      [this.addressesOfMap[0].tag, this.addressesOfMap[1].tag] = [this.addressesOfMap[1].tag,
        this.addressesOfMap[0].tag];
      this.addressesOfMap.reverse();
    },
    fetchAntelacionLAP() {
      if (this.idCliente) {
        fetchClientes(this.idCliente)
          .then(({ antelacionAlta }) => {
            this.antelacionLAP = antelacionAlta;
          })
          .catch((error) => {
            this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
          });
      }
    },
  },
});
