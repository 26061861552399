





















import { capitalizeText, propOfType } from '@/services/utils';
import { ServicioDisponible } from '@/models/taaxii.com/taaxiiServicioDisponible';
import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  props: {
    selected: Boolean,
    service: propOfType<ServicioDisponible>(),
    description: String,
    mostrarTiempoDeEspera: Boolean,
    dateRegreso: propOfType<Date | null>(null),
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    name(): string {
      return capitalizeText(this.service.tipoVehiculo);
    },
    time(): string {
      const { esperaMin, esperaMax } = this.service;
      if (esperaMin) {
        return `Pasará a buscarte en ${esperaMin} minutos.`;
      }
      return `Pasará el ${esperaMin} hs.`;
    },
    money(): string {
      if (this.service.precotizacion < 0) return 'Sin cotización';
      return `$${this.service.precotizacion + (this.dateRegreso
        ? ' (por tramo)' : '')}`;
    },
  },
});
