





















































import IconCar from '@/components/icons/IconCar.vue';
import { ServicioDisponible } from '@/models/taaxii.com/taaxiiServicioDisponible';
import { get } from '@/services/http';
import Vue from 'vue';
import { AxiosError } from 'axios';
import { capitalizeText, propOfType, propOfTypeArrayOf } from '@/services/utils';
import { Address } from '@/models/Address';
import { GetServiciosDisponiblesReq } from '@/models/requests-and-responses/sgv';
import { apply } from '../../indexForm.vue';
import ServiceItem from './ServiceItem.vue';
import SectionLayout from '../SectionLayout.vue';

export default Vue.extend({
  components: {
    SectionLayout,
    ServiceItem,
    IconCar,
  },
  data() {
    return {
      services: [] as ServicioDisponible[],
      indexOfSelectedServices: 0,
      loading: false,
      thereWasAFetch: false,
    };
  },
  mounted() {
    this.fetch();
  },
  props: {
    isExpanded: Boolean,
    addresses: propOfTypeArrayOf<Address | undefined>(),
    idCliente: Number,
    date: propOfType<Date | null>(null),
    dateRegreso: propOfType<Date | null>(null),
    userTriedToConfirm: Boolean,
    isLoAntesPosible: Boolean,
    service: propOfType<ServicioDisponible | null>(null),
  },
  watch: {
    addresses() { this.fetch(); },
    idCliente() { this.fetch(); },
    date() { this.fetch(); },
    selectedServicioDisponible(val) {
      apply(this, (data) => {
        data.service = val;
      });
    },
  },
  computed: {
    selectedServicioDisponible(): ServicioDisponible | null {
      const ssd: ServicioDisponible | undefined = this.services[this.indexOfSelectedServices];
      return (ssd) || null;
    },
    isExpandable(): boolean {
      return this.services.length > 1;
    },
    selectedServicioDisponibleText(): string | null {
      return this.selectedServicioDisponible !== null && !this.isExpanded
        ? capitalizeText(this.selectedServicioDisponible.tipoVehiculo)
        : null;
    },
    minutosDeEsperaText(): string | null {
      const ssd = this.selectedServicioDisponible;
      if (ssd && ssd.esperaMin) {
        const esperaMax = ssd.esperaMax ? ` - ${ssd.esperaMax}` : '';
        return `${ssd.esperaMin}${esperaMax} min`;
      }
      return null;
    },
    headerText(): string {
      const ssd = this.selectedServicioDisponible;

      if (this.isExpanded || this.selectedServicioDisponible !== null) {
        return 'Servicio';
      } if (this.loading) {
        return 'Buscando servicios...';
      } if (this.thereWasAFetch) {
        return 'Sin servicios';
      }
      return 'Servicio';
    },
    error() {
      if (!this.userTriedToConfirm) return '';
      if (this.loading) return '';
      if (this.selectedServicioDisponible) return '';
      if (this.thereWasAFetch) return 'Sin servicio';
      return '';
    },
  },
  methods: {
    getParsedLocationOf(address: Address): string {
      // Por algún motivo el server espera localidades escritas en una
      // localidad específica. No cualquiera sirve.
      return 'Cordoba, Cordoba';
    },
    getDateNotNull(): Date {
      const d = this.date;
      return d || new Date();
    },
    fetch(): void {
      const filteredAdds = this.addresses.filter((adds) => adds) as Address[];

      if (filteredAdds.length < 1 || this.addresses.length > filteredAdds.length) {
        this.services = [];
        this.indexOfSelectedServices = 0;
        this.thereWasAFetch = false;
        return;
      }

      const req: GetServiciosDisponiblesReq = {
        idCliente: this.idCliente,
        paradas: filteredAdds.map(({ lat, lon }) => `${lat},${lon}`).join(':'),
      };

      if (this.date) req.fechaHoraSalida = this.date;

      this.loading = true;
      this.services = [];
      get('$sgv/rest/v3/servicios', req).then((res) => {
        const conPrecotizacion = res.data.filter((service) => service.precotizacion !== -1);
        conPrecotizacion.push(...res.data.filter((service) => !conPrecotizacion.includes(service)));
        this.services = conPrecotizacion;
        // En caso de que el indice anterior provoque que
        // haya un out of bound, hacemos que el indice del seleccionado
        // vuelva a ser 0.
        if (res.data.length > (this.indexOfSelectedServices + 1)) {
          this.indexOfSelectedServices = 0;
        }
        if (this.service) {
          const servIndex = this.services
            .findIndex((serv) => serv.tipoVehiculo === this.service.tipoVehiculo);
          if (servIndex > -1) this.indexOfSelectedServices = servIndex;
        }
      }).catch((_: AxiosError) => {
        // No vamos a largar un toast porque, por ejemplo
        // uno de los errores que podrían venir del backend es a causa
        // de que hemos enviado una fechaHora anterior a la fecha actual.
        // No queremos que estas cosas generen un toast, sino que ya el propio
        // formulario nos la va a hacer notar.
        // this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
        this.services = [];
      }).finally(() => {
        this.loading = false;
        this.thereWasAFetch = true;
      });
    },
    mayToggle() {
      if (this.isExpandable || this.isExpanded) {
        this.$emit('toggle');
      }
    },
    onItemSelection(index: number) {
      this.indexOfSelectedServices = index;
      this.$emit('close');
    },
  },
});
