




















import Map from '@/components/map/Map.vue';
import {
  stopMarker, withTooltip, Marker, originMarker, destinationMarker, filterMarkers,
} from '@/components/map/Marker';
import { Coords, toCoords } from '@/services/Coords';
import { propOfType } from '@/services/utils';
import Vue from 'vue';

export interface AddressForMap {
  lat: number
  lon: number
  tag: string
}

export default Vue.extend({
  components: {
    Map,
  },
  props: {
    addresses: propOfType<AddressForMap[]>(),
    onDistanceChangedCallback: Function,
    onTimeChangedCallback: Function,
  },
  computed: {
    mapMarkers(): Array<{ coords: Coords, marker: Marker } | undefined> {
      return this.addresses.map((address, index) => {
        let marker: Marker;
        if (address === undefined) return undefined;
        if (index === 0) {
          marker = originMarker();
        } else if (index === this.addresses.length - 1) {
          marker = destinationMarker();
        } else {
          marker = stopMarker();
        }

        return {
          coords: toCoords(address),
          marker: withTooltip(address.tag.toLocaleLowerCase(), marker),
        };
      });
    },
    filteredMarkers(): Array<{ coords: Coords, marker: Marker } | undefined> {
      return filterMarkers(this.mapMarkers, false);
    },
    mapRoute(): Array<Coords | undefined> {
      return this.mapMarkers.map((mapMarkers) => (mapMarkers ? mapMarkers.coords : undefined));
    },
    mapZoom(): number {
      return 11;
    },
    mapPadding(): { left: number, top: number, bottom: number, right: number } {
      return {
        left: this.isMobile ? 50 : 450,
        top: 100,
        bottom: 100,
        right: 50,
      };
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
});
