



























import Vue from 'vue';

export default Vue.extend({
  props: {
    loading: Boolean,
    price: String,
    text: String,
  },
});
